<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">
            <el-form-item label="">
              <el-button type="success" size="small" @click="addOption">新增</el-button>
            </el-form-item>
            <el-form-item label="" style="margin-left: 2vw">
              <el-input v-model="searchName" placeholder="请输入名称"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-button style="margin-left: 1vw;" type="success" size="small" @click="search">搜索</el-button>
      </div>
    </div>
    <el-table
        v-loading="loading"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 1vh;">
      <el-table-column prop="baseName" label="公司名称" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="baseAddress" label="公司地址" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="baseArea" label="公司面积" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="basePrincipal" label="公司负责人" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="tel" label="负责人电话" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="introduce" label="公司简介" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <!-- <el-button
            type="primary"
            size="small"
            icon="el-icon-document"
            @click="details(scope.row)"
          ></el-button> -->
          <el-button
              type="primary"
              size="small"
              icon="el-icon-edit"
              @click="edits(scope.row)"
          ></el-button>
          <el-button
              type="primary"
              size="small"
              icon="el-icon-delete"
              @click="deletes(scope.row.id)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
    <!--    编辑-->
    <el-dialog title="" :visible.sync="messageBox" width="70vw" class="addAlameBox" :close-on-click-modal="false">
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0px; font-size: 2.22vh;"></span>
      </div>
      <div class="addAlaForm">
        <el-form ref="addForm" :rules="rules" :model="addForm" label-width="11vw">
          <el-form-item label="公司名称" prop="baseName">
            <!-- <el-date-picker v-model="addForm.nyr" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker> -->
            <el-input v-model="addForm.baseName" placeholder="请输入公司名称"></el-input>
          </el-form-item>
          <el-form-item label="公司地址" prop="baseAddress">
            <el-input v-model="addForm.baseAddress" placeholder="请输入公司地址"></el-input>
          </el-form-item>
          <el-form-item label="公司面积" prop="baseArea">
            <el-input type="number" v-model="addForm.baseArea" placeholder="请输入公司面积"></el-input>
          </el-form-item>
          <el-form-item label="公司负责人" prop="basePrincipal">
            <el-input v-model="addForm.basePrincipal" placeholder="请输入公司负责人"></el-input>
          </el-form-item>
          <el-form-item label="负责人电话" prop="tel">
            <el-input v-model="addForm.tel" placeholder="请输入负责人电话"></el-input>
          </el-form-item>
          <el-form-item label="气象设备" prop="weatherDevice">
            <el-select value-key="id" v-model="weatherVlue" placeholder="请选择气象设备" multiple>
              <el-option v-for="item in weatherList" :key="item.deviceNum" :label="item.deviceName"
                         :value="item.deviceNum">
              </el-option>
            </el-select>
          </el-form-item>
<!--          <el-form-item label="控制设备" prop="relayDevice">
            <el-select value-key="id" v-model="relaySet" placeholder="请选择控制设备" @change="relayChange" multiple>
              <el-option v-for="item in relayList" :key="item.dataPointRelId" :label="item.name"
                         :value="item.dataPointRelId">
              </el-option>
            </el-select>
          </el-form-item>-->
          <el-form-item label="土壤设备" prop="soilDevice">
            <el-select value-key="id" v-model="soilSet" placeholder="请选择土壤设备" multiple>
              <el-option v-for="item in soilList" :key="item.deviceNum" :label="item.deviceName"
                         :value="item.deviceNum">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="水质设备" prop="waterDevice">
            <el-select value-key="id" v-model="waterSet" placeholder="请选择水质设备" multiple>
              <el-option v-for="item in waterQualityList" :key="item.deviceNum" :label="item.deviceName"
                         :value="item.deviceNum">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="摄像头设备" prop="cameraDevice">
            <el-select value-key="id" v-model="cameraSet" placeholder="请选择摄像头设备" @change="cameraChange" multiple>
              <el-option v-for="item in cameraList" :key="item.id" :label="item.deviceName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="虫情" prop="insectDevice">
            <el-select value-key="id" v-model="insectSet" placeholder="请选择虫情设备" multiple>
              <el-option v-for="item in insectList" :key="item.deviceNumber" :label="item.name"
                         :value="item.deviceNumber">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="孢子" prop="sporeDevice">
            <el-select value-key="id" v-model="sporeSet" placeholder="请选择孢子设备" multiple>
              <el-option v-for="item in sporeList" :key="item.deviceNumber" :label="item.name"
                         :value="item.deviceNumber">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公司简介" prop="introduce">
            <el-input type="textarea" v-model="addForm.introduce" placeholder="请输入公司简介"></el-input>
          </el-form-item>
          <el-form-item class="isUpload" label="图片" prop="baseImg" style="width: 60%">
            <el-upload
                class="avatar-uploader"
                action="/imgUpdata"
                list-type="picture-card"
                :on-success="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload2"
                :on-remove="removeList"
                :on-error="imgerr"
                :file-list="cImgfileList"
                :headers="{token}"
            >
              <i class="el-icon-plus avatar-uploader-icon uploadImg"></i>
              <div>
                <img
                    v-for="item, i in cImgfileList" :key="i"
                    class="businessImg avatar"
                    :src="item.url" alt=""
                >
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item label="中心点位" prop="centerPos">
            <!--  prop="centerPos"   centerPosition -->
            <el-input v-model="addForm.centerPos" placeholder="请输入中心点位"></el-input>
          </el-form-item>
          <el-form-item label="绘制地块" style="width:100%;height:35vh;">
            <amap v-if="messageBox" ref="amap" :center="mapCenter" @polygonPath="polygonPath"></amap>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" v-show="showFlag">
          <el-button type="info" @click="messageBox = false">取消</el-button>
          <el-button type="primary" @click="saveForm('addForm')">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import amap from "@/components/map/drawPolygonByAmap.vue";

export default {
  components: {amap},
  name: 'sensorManagement',
  data() {
    return {
      // 地图
      mapCenter: [],
      pathArr: [],
      centerPosition: [],
      tableData: [],
      page: 0,
      size: 20,
      total: 0,
      loading: false,
      ids: 0,
      searchName: '',
      addForm: {
        baseImg: [],
      },
      rules: {
        baseName: [
          {required: true, message: "公司名称不可为空", trigger: ["blur", "change"]},
        ],
        /*baseAddress: [
          {required: true, message: "公司地址不可为空", trigger: ["blur", "change"]},
        ],
        baseArea: [
          {required: true, message: "公司面积不可为空", trigger: ["blur", "change"]},
        ],
        basePrincipal: [
          {required: true, message: "公司负责人不可为空", trigger: ["blur", "change"]},
        ],*/
        centerPos: [
          {required: true, message: "中心点不可为空", trigger: ["blur", "change"]},
        ],/*
        tel: [
          {required: true, message: "负责人电话不可为空", trigger: ["blur", "change"]},
        ],*/
        introduce: [
          {required: true, message: "公司简介不可为空", trigger: ["blur", "change"]},
        ],

      },
      formTitle: "",
      messageBox: false,
      showFlag: false,
      value: true,
      deviceData: [],
      weatherList: [],
      irrigateList: [],
      soilList: [],
      waterQualityList: [],
      cameraList: [],
      insectList: [],
      sporeList: [],
      relayList: [],
      weatherVlue: [],
      soilSet: [],
      waterSet: [],
      cameraSet: [],
      insectSet: [],
      sporeSet: [],
      relaySet: [],
      relayDeviceNew: "",
      newRely: "",
      newCamera: "",
      token: '',
      cImgfileList: [],
      urlList: [],
      baseFileName: [],
    };
  },
  mounted() {
    this.token = JSON.parse(window.localStorage.getItem('token'));
    this.getTitle();
    this.getList();
    this.getDeviceList();
    this.getCameraList();
    this.getInsectList();
    this.getrelayList();
  },
  methods: {
    getTitle() {
      this.$get("/largeScreenManagement/getLargeScreenManagements", {
        page: 1,
        size: 100
      }).then((res) => {
        if (res.data.state == 'success') {
          this.mapCenter = res.data.datas[0].location.split(",")

        }
      })
    },
    //请求列表数据
    getList() {
      this.$get("/baseManagement/getBaseManagements", {
        page: this.page,
        size: this.size,
        baseName: this.searchName,
      }).then((res) => {
        if (res.data.state == "success") {
          this.tableData = res.data.datas;
          this.total = res.data.count;
        }
      });
    },

    // 地图
    polygonPath(path, center, type) {
      this.pathArr = path;
      if (!this.addForm.centerPos) {
        this.addForm.centerPos = center[0] + ',' + center[1];
        this.addForm = JSON.parse(JSON.stringify(this.addForm))
      }

    },

    //   获取设备列表
    async getDeviceList() {
      const {data} = await this.$get("/deviceManage/getDeviceInfos", {
        page: 1,
        size: 10000,
        limit: 10000,
        deviceNum: '',
        deviceName: '',
      });
      var datas = data.datas;
      this.deviceData = datas;
      for (let a = 0; a < datas.length; a++) {
        if (datas[a].deviceType == '1') {
          this.weatherList.push(datas[a]);
        } else if (datas[a].deviceType == '3') {
          this.soilList.push(datas[a]);
        } else if (datas[a].deviceType == '4') {
          this.waterQualityList.push(datas[a]);
        }

      }
    },
    // 获取摄像头列表
    async getCameraList() {
      const {data} = await this.$get("/cameraManage/page", {
        page: 1,
        size: 10000,
      });
      var datas = data.datas;
      this.cameraList = datas;
    },
    cameraChange(val) {
      let data;
      let cameraId = [];
      for (let a = 0; a < val.length; a++) {
        data = this.cameraList.find((v) => v.id == val[a]).deviceNum;
        cameraId.push(data + '_' + val[a])
      }
      this.newCamera = cameraId;
    },
    // 获取虫情列表
    async getInsectList() {
      const {data} = await this.$get("/insectManage/page", {
        page: 1,
        size: 10000,
      });
      var datas = data.datas;
      for (let a = 0; a < datas.length; a++) {
        if (datas[a].deviceType == 6 || datas[a].deviceType == '6') {
          this.insectList.push(datas[a]);
        } else {
          this.sporeList.push(datas[a]);
        }

      }
    },
    // 获取控制列表
    async getrelayList() {
      const {data} = await this.$get("/deviceCtrl/getSecret", {
        page: 1,
        size: 10000,
      });
      var datas = data.data;
      let deviceNum = datas ? datas.deviceNumber : "";
      this.relayDeviceNew = deviceNum;
      if (deviceNum != undefined || deviceNum != null) {
        this.$get('/deviceCtrl/pageDataPointInfoForCusdeviceNo/', {
          deviceNum: deviceNum,
          page: 1,
          size: 10000,
        }).then((res) => {
          let data = res.data.data.cusdeviceDataPointList;
          this.relayList = data;
        })
      }
    },
    relayChange(val) {
      let data;
      let relyId = [];
      for (let a = 0; a < val.length; a++) {
        data = this.relayList.find((v) => v.dataPointRelId == val[a]).deviceNum;
        relyId.push(data + '_' + val[a])
      }
      this.newRely = relyId;
    },

    // 编辑
    edits(row) {
      this.showFlag = true;
      this.cImgfileList = [];
      this.baseFileName = [];
      this.ids = row.id;
      this.formTitle = "编辑";
      this.weatherVlue = [],
          this.relaySet = [],
          this.soilSet = [],
          this.waterSet = [],
          this.cameraSet = [],
          this.insectSet = [],
          this.sporeSet = [],
          this.addForm = JSON.parse(JSON.stringify(row));
      this.pathArr = row.polygonWkt ? JSON.parse(row.polygonWkt) : [];
      this.messageBox = true;
      if (row.baseImg && row.baseImg.length) {
        let baseImg = JSON.parse(row.baseImg);

        for (let a = 0; a < baseImg.length; a++) {
          let url = "https://public.half-half.cn/" + baseImg[a]
          this.cImgfileList.push({name: baseImg[a], url: url});
          this.baseFileName.push(baseImg[a]);


        }

      }
      if (row.weatherDevice !== "[]") {
        this.weatherVlue = JSON.parse(row.weatherDevice)
      } else {
        this.weatherVlue = [];
      }
      if (row.relayDevice !== "[]") {
        this.relaySet = JSON.parse(row.relayDevice);
      } else {
        this.relaySet = [];
      }
      if (row.soilDevice !== "[]") {
        this.soilSet = JSON.parse(row.soilDevice);
      } else {
        this.soilSet = [];
      }
      if (row.waterDevice !== "[]") {
        this.waterSet = JSON.parse(row.waterDevice);
      } else {
        this.waterSet = [];
      }
      if (row.cameraDevice !== "[]") {
        this.cameraSet = JSON.parse(row.cameraDevice);
      } else {
        this.cameraSet = [];
      }
      if (row.insectDevice !== "[]") {
        this.insectSet = JSON.parse(row.insectDevice);
      } else {
        this.insectSet = [];
      }

      if (row.sporeDevice !== "[]") {
        this.sporeSet = JSON.parse(row.sporeDevice);
      } else {
        this.sporeSet = [];
      }
      this.sporeSet = row.sporeDevice;
      this.$nextTick(() => {
        this.$refs.amap.showPath(this.pathArr);
      })

    },
    // 删除
    deletes(id) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.$delete(`/baseManagement/delBaseById/${id}`).then((res) => {
              if (res.data.state == "success") {
                this.$message.success("删除成功");
                this.getList();
              } else {
                this.$message.error("删除失败");
              }
            });
          })
          .catch((e) => e);
    },
    imgerr(data) {
      //console.log('imgerr',data)
    },
    // 确认提交
    saveForm(formName) {
      // let id = this.ids;
      let param = this.addForm;
      this.addForm.id = this.ids;
      let baseDevice = []
      if (this.weatherVlue) {
        for (let i = 0; i < this.weatherVlue.length; i++) {
          baseDevice.push({
            type: 1,
            deviceNumber: this.weatherVlue[i],
          })
        }
      }
      if (this.relaySet) {
        for (let i = 0; i < this.relaySet.length; i++) {
          baseDevice.push({
            type: 2,
            deviceNumber: this.relaySet[i],
          })
        }
      }
      if (this.soilSet) {
        for (let i = 0; i < this.soilSet.length; i++) {
          baseDevice.push({
            type: 3,
            deviceNumber: this.soilSet[i],
          })
        }
      }
      if (this.waterSet) {
        for (let i = 0; i < this.waterSet.length; i++) {
          baseDevice.push({
            type: 4,
            deviceNumber: this.waterSet[i],
          })
        }
      }
      if (this.newCamera) {
        for (let i = 0; i < this.newCamera.length; i++) {
          baseDevice.push({
            type: 5,
            deviceNumber: this.newCamera[i],
          })
        }
      }
      if (this.insectSet) {
        for (let i = 0; i < this.insectSet.length; i++) {
          baseDevice.push({
            type: 6,
            deviceNumber: this.insectSet[i],
          })
        }
      }
      if (this.sporeSet) {
        for (let i = 0; i < this.sporeSet.length; i++) {
          baseDevice.push({
            type: 7,
            deviceNumber: this.sporeSet[i],
          })
        }
      }

      param.baseManagementToDeviceBeanList = baseDevice;
      let url = '/baseManagement/addOrUpdateBase';
      if (this.formTitle == '新增') {
        delete this.addForm.id;
      }

      if (!this.pathArr.length) {
        //  || !this.centerPosition.length  //中心点
        //return this.$message.error("请绘制地块");
      }
      param.polygonWkt = JSON.stringify(this.$refs.amap.dealPath(this.pathArr));
      // param.centerPos = JSON.stringify(this.centerPosition);
      param.relayDevice = this.relaySet ? JSON.stringify(this.relaySet) : '';
      param.weatherDevice = this.weatherVlue ? JSON.stringify(this.weatherVlue) : '';
      param.soilDevice = this.soilSet ? JSON.stringify(this.soilSet) : '';
      param.waterDevice = this.waterSet ? JSON.stringify(this.waterSet) : '';
      param.cameraDevice = this.cameraSet ? JSON.stringify(this.cameraSet) : '';
      param.insectDevice = this.insectSet ? JSON.stringify(this.insectSet) : '';
      param.sporeDevice = this.sporeSet ? JSON.stringify(this.sporeSet) : '';
      param.baseImg = this.baseFileName ? JSON.stringify(this.baseFileName) : '';
      //console.log(param);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$postJSON(url, this.addForm
          ).then((res) => {
            if (res.data.state == 'success') {
              if (this.formTitle == '新增') {
                this.$message.success('添加成功')
              } else {
                this.$message.success('修改成功')
              }
              this.messageBox = false
              this.getList();
            } else {
              this.$message.error(res.data.msg)
              this.messageBox = false
            }
          })
        } else {
          // //console.log('error submit!!');
          return false;
        }
      });
    },
    // 新增
    addOption() {
      this.formTitle = "新增";
      this.messageBox = true;
      this.pathArr = [];
      this.centerPosition = [];
      this.showFlag = true;
      this.baseFileName = [];
      this.$nextTick(() => {
        this.addForm = {};
        this.addForm.baseImg = [];
        this.cImgfileList = [];
        this.urlList = [];
        this.weatherVlue = "";
        this.relaySet = "";
        this.soilSet = "";
        this.waterSet = "";
        this.cameraSet = "";
        this.insectSet = "";
        this.sporeSet = "";
        this.$refs.addForm.resetFields();
      });
    },
    search() {
      this.page = 1
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },

    beforeAvatarUpload2(val) {
      const fileLimitSize = 2;// 几兆限制
      const isJPG = val.type === "image/jpeg";
      const isJPGs = val.type === "image/png";
      const fileLimit = val.size / 1024 / 1024 < fileLimitSize;
      if (!isJPG && !isJPGs) {
        this.$message.info("上传图片格式只能为 JPG 或 PNG 格式！");
        this.fileList2 = [];
        return false
      }
      if (!fileLimit) {
        this.$message.info(`上传图片大小不能超过${fileLimitSize}MB!`);
        this.fileList2 = [];
        return false
      }

      return true;
    },
    handleAvatarSuccess2(file) {
      this.$getBlob("/fileops/show", {
        fileName: file.data,
      }).then((res) => {
        let fileNames = res.config.params.fileName;
        let fileData = fileNames.split(".")[1];
        let blob = new Blob([res.data], {type: 'image/' + fileData})
        let url = window.URL.createObjectURL(blob);
        this.cImgfileList.push({name: file.data, url: url});
        this.urlList.push(url);
        this.addForm.baseImg = this.urlList;
        this.baseFileName.push(file.data);
      })
    },
    removeList(file, fileList) {
      //console.log(file, fileList);
      this.cImgfileList = fileList;
      this.baseFileName = [];
      let list = [];
      for (let a = 0; a < this.cImgfileList.length; a++) {
        list.push(this.cImgfileList[a].url);
        this.baseFileName.push(this.cImgfileList[a].name);
      }
      this.addForm.baseImg = list;
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

#content /deep/ .el-table::-webkit-scrollbar {
  display: none;
}

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}

.dialog-footer /deep/ .el-button, .el-button--info {
  margin-left: 10px !important;

}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}

.drawerBox /deep/ .msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 1vw;
}

.addAlameBox /deep/ .el-dialog__body {
  padding: 3vh 1vw 3vh 0.5vw;
}

.addAlameBox /deep/ .el-form {
  display: flex;
  flex-wrap: wrap;
}

.addAlameBox /deep/ .el-form .el-form-item {
  width: 33%;
}

.isUpload /deep/ .el-upload--picture-card {
  line-height: 12vh;
}

.isUpload /deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 12vh;
  height: 12vh;
}

.isUpload /deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.isUpload /deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 12vh;
  height: 12vh;
  line-height: 12vh;
  text-align: center;
}

.isUpload /deep/ .el-upload-list__item {
  width: 12vh;
  height: 12vh;
}

.isUpload .businessImg {
  width: 12vh;
  height: 12vh;
}

.imgs {
  width: 6vh;
  height: 6vh;
}

</style>


